<template>
  <b-card class="card-congratulation-medal card-account-setting h-90">
    <b-card-header
      class="pl-0 pt-0"
    >
      <div v-if="isUserAdmin">
        <b-card-title>Account Details</b-card-title>
      </div>
      <div v-else>
        <b-card-title>Account Settings</b-card-title>
      </div>
    </b-card-header>
    <b-card-body class="pl-0">
      <div
        class="employee-task d-flex justify-content-between align-items-center"
      >
        <b-media no-body>
          <b-media-aside class="mr-75">
            <b-img
              :src="currentUser.image ? currentUser.image: require('@/assets/images/avatars/User-place-holder.svg') "
              height="80"
              width="80"
            />
          </b-media-aside>
          <b-media-body class="my-auto">
            <div class="text-truncate email-div-class">
              <feather-icon
                icon="UserIcon"
                class="icon"
              />
              <span
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.top="currentUser.name"
                class="mb-0 profile-text-class"
              >
                {{ currentUser.name }}
              </span>
            </div>
            <div class=" email-div-class">
              <feather-icon
                icon="MailIcon"
                class="icon"
              />
              <small
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.top="currentUser.email"
                class="pl-0 mr-1 profile-text-class"
              > {{ currentUser.email }}</small>
            </div>
          </b-media-body>
        </b-media>
      </div>
    </b-card-body>
    <b-button
      v-if="!isUserAdmin"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="viewSettings"
    >
      View Account
    </b-button>
    <b-img
      :src="require('@/assets/images/simiicons/Intersection 1.png')"
      class="congratulation-medal"
      alt="Medal Pic"
    />
  </b-card>
</template>

<script>
import {
  BCardBody,
  BCard, BButton, BImg, BCardHeader, BMediaBody, BMediaAside, BCardTitle, BMedia, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BImg,
    BButton,
    BCardBody,
    BCardHeader,
    BMediaBody,
    BMediaAside,
    BCardTitle,
    BMedia,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters['user/getUser']
    },
    isUserAdmin() {
      return this.$store.getters['user/isUserAdmin']
    },
  },
  methods: {
    viewSettings() {
      this.$router.push('/account-setting')
    },
  },
}
</script>
<style lang="scss">
.card-account-setting {

.profile-text-class {
  padding-left: 2px;
  font-size: 14px;
  line-height: 22px;
  color: #383838;
  font-weight: 400;
  font-family: "Montserrat";
}
.icon {
  width: 20px;
  height: 20px;
  padding-right: 5px;
  color: black;
}
.email-div-class {
  max-width: 500px;
  @media (max-width: 1830px) {
    max-width: 500px;
  }
  @media (max-width: 1440px) {
    max-width: 275px;
  }
  @media (max-width: 1276px) {
    max-width: 275px;
  }
  @media (max-width: 848px) {
    max-width: 220px;
  }
 }
}
</style>
